<template>
  <div class="knowledge_point_line">
    <TopCard ref="TopCardRef"
             :text="userGrade!=1?'知识点曲线':'考点曲线'"
             :showBack="true" />
    <SubjectCard ref="SubjectCardRef"
                 @initList="handle" />
    <div class="content">
      <div class="left_subject">
        <div v-if="userGrade!=1">
          <div :class="{'item':true,'isActive':activeList.indexOf(item.knowledge_id) != -1 }"
               v-for="(item,index) in knowledgeList"
               @click="addSubject(item)"
               :key="index">
            {{item.knowledge_desc}}
          </div>
        </div>
        <div v-else>
          <div :class="{'item':true,'isActive':activeList.indexOf(item.check_point_id)!= -1 }"
               v-for="(item,index) in knowledgeList"
               @click="addSubject(item)"
               :key="index">
            {{item.check_point_desc}}
          </div>
        </div>
      </div>
      <div class="right_charts">
        <div class="charts">
          <defen :textList="textList"
                 :grade="userGrade"
                 ref="defenRef" />
        </div>
        <div class="charts">
          <leiji :textList="textList"
                 :grade="userGrade"
                 ref="leijiRef" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defen from './components/defen.vue'
import leiji from './components/leiji.vue'
import TopCard from '@/views/components/top_card/index.vue'
import SubjectCard from '@/views/components/subject_card/index.vue'
import { getKnowledgeLine, getPointLine } from '@/api/analysis.js'
export default {
  data () {
    return {
      subjectList: [],
      knowledgeList: [],
      activeList: [],
      textList: [],
      id: '',
      tipText: '请选择知识点',
      tipText1: '请选择知识点',
      userGrade: undefined
    }
  },
  components: {
    defen,
    leiji, TopCard, SubjectCard
  },
  mounted () {
    this.userGrade = JSON.parse(window.localStorage.getItem('userInfo')).edu_grade_id
    this.$refs.SubjectCardRef.getSubjectList()
  },
  methods: {
    addSubject (item) {
      if (this.userGrade != 1) {
        if (this.activeList.indexOf(item.knowledge_id) != -1) {
          this.activeList.splice(this.activeList.indexOf(item.knowledge_id), 1)
          this.textList.splice(this.textList.indexOf(item.knowledge_desc), 1)
          this.getKnowledge()
          return
        }
        if (this.activeList.length == 5) return
        this.textList.push(item.knowledge_desc)
        this.activeList.push(item.knowledge_id)
        this.getKnowledge()
      } else {
        if (this.activeList.indexOf(item.check_point_id) != -1) {
          this.activeList.splice(this.activeList.indexOf(item.check_point_id), 1)
          this.textList.splice(this.textList.indexOf(item.check_point_desc), 1)
          this.getPoint()
          return
        }
        if (this.activeList.length == 5) return
        this.textList.push(item.check_point_desc)
        this.activeList.push(item.check_point_id)
        this.getPoint()
      }

    },
    getKnowledgeList (id) {
      this.id = id
      this.activeList = []
      this.getKnowledge()
      this.$http({
        url: '/api/v1/public/get_knowledge1',
        methos: 'get',
        params: {
          subject_id: id
        }
      }).then(res => {
        this.knowledgeList = res.data.list
      })
    },
    getPointList (id) {
      this.id = id
      this.activeList = []
      this.getPoint()
      this.$http({
        url: '/api/v1/public/checkpoint_by_subject',
        methos: 'get',
        params: {
          subject_id: id
        }
      }).then(res => {
        this.knowledgeList = res.data.list
      })
    },
    async getKnowledge () {
      let arr = []
      let arr1 = []
      let maxLength = 5
      let maxLength1 = 5
      if (this.activeList.length == 0) {
        this.$refs.defenRef.data1 = arr
        this.$refs.leijiRef.data1 = arr1
        this.textList = []
        return
      }
      const { data } = await getKnowledgeLine({
        knowledgeIds: this.activeList.join(','),
        subjectId: this.id
      })
      if (data.length == 0) {
        this.tipText = '暂无数据'
        this.tipText1 = '暂无数据'
        return
      }
      data.forEach((item, index) => {
        let obj = {
          name: item.knowledgeDesc,
          type: 'line',
          smooth: true,
          data: item.res.length ? item.res : [0, 0, 0, 0, 0],
        }
        let obj1 = {
          name: item.knowledgeDesc,
          type: 'line',
          smooth: true,
          data: item.res1.length ? item.res1 : [0, 0, 0, 0, 0, 0],
        }
        arr.push(obj)
        arr1.push(obj1)
        if (item.res.length > maxLength) {
          maxLength = item.res.length
        }
        if (item.res1.length > maxLength1) {
          maxLength1 = item.res1.length
        }
        let xValues = [], xValues1 = []
        for (let i = 0; i < maxLength; i++) {
          xValues.push('')
        }
        for (let i = 0; i < maxLength1; i++) {
          xValues1.push('')
        }
        // console.log(arr1)
        if (index == this.activeList.length - 1) {
          this.$refs.defenRef.data1 = arr
          this.$refs.leijiRef.data1 = arr1
          this.$refs.defenRef.xValues = xValues
          this.$refs.leijiRef.xValues = xValues1
        }
      })

    },
    async getPoint () {
      let arr = []
      let arr1 = []
      let maxLength = 5
      let maxLength1 = 5
      if (this.activeList.length == 0) {
        this.$refs.defenRef.data1 = arr
        this.$refs.leijiRef.data1 = arr1
        this.textList = []
        return
      }
      const { data } = await getPointLine({
        checkPointIds: this.activeList.join(','),
        subjectId: this.id
      })
      if (data.length == 0) {
        this.tipText = '暂无数据'
        this.tipText1 = '暂无数据'
        return
      }
      data.forEach((item, index) => {
        let obj = {
          name: item.checkPointDesc,
          type: 'line',
          smooth: true,
          data: item.res.length == 0 ? [0, 0, 0, 0, 0] : item.res,
        }
        let obj1 = {
          name: item.checkPointDesc,
          type: 'line',
          smooth: true,
          data: item.res1.length == 0 ? [0, 0, 0, 0, 0, 0] : item.res1,
        }
        arr.push(obj)
        arr1.push(obj1)
        if (item.res.length > maxLength) {
          maxLength = item.res.length

        }
        if (item.res1.length > maxLength1) {
          maxLength1 = item.res1.length
        }
        let xValues = [], xValues1 = []
        for (let i = 0; i < maxLength; i++) {
          xValues.push('')
        }
        for (let i = 0; i < maxLength1; i++) {
          xValues1.push('')
        }
        // console.log(arr1)
        if (index == this.activeList.length - 1) {
          this.$refs.defenRef.data1 = arr
          this.$refs.leijiRef.data1 = arr1
          this.$refs.defenRef.xValues = xValues
          this.$refs.leijiRef.xValues = xValues1
        }
      })

    },
    handle (id) {
      console.log('ida', id)
      if (this.userGrade == 1) {
        this.getPointList(id)
      } else {
        this.getKnowledgeList(id)
      }
    }



    //   this.activeList.forEach((item, index) => {
    //     this.$http({
    //       url: '/api/v1/report/knowledge_charts',
    //       method: 'POST',
    //       data: {
    //         knowledge_id: item,
    //         subject_id: this.id
    //       }
    //     }).then(res => {
    //       let obj = {
    //         name: this.textList[index],
    //         type: 'line',
    //         data: res.data.list.acc_rate.length ? res.data.list.acc_rate : [0, 0, 0, 0, 0],
    //       }
    //       let obj1 = {
    //         name: this.textList[index],
    //         type: 'line',
    //         data: res.data.list.rate.length ? res.data.list.rate : [0, 0, 0, 0, 0, 0],
    //       }
    //       arr.push(obj)
    //       arr1.push(obj1)
    //       if (index == this.activeList.length - 1) {
    //         this.$refs.defenRef.data1 = arr
    //         this.$refs.leijiRef.data1 = arr1
    //         setTimeout(() => {
    //           //this.$refs.leijiRef.drawLine()
    //           //this.$refs.defenRef.drawLine()
    //         }, 100);
    //       }
    //     })
    //   })
  }
}

</script>

<style lang="scss" scoped>
.knowledge_point_line {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
}
::v-deep .el-tabs__nav .is-top {
  margin: 0 auto;
  width: 120rem;
  text-align: center;
  font-size: 18rem;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__nav-wrap {
  background: white;
}
.content {
  display: flex;
  .left_subject {
    // overflow-y: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 28rem 0;
    width: 300rem;
    height: calc(100vh - 240rem) !important;
    background: #ebf3fa;
    .item {
      text-indent: 1em;
      min-width: 280rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 60rem;
      line-height: 60rem;
      margin: 0rem 2rem;
      padding: 0 15rem;
      font-size: 20rem;
      font-weight: bold;
      color: #666666;
      border-bottom: 1rem solid #ebeaea;
    }
    /* 整个滚动条 */
    &::-webkit-scrollbar {
      width: 8rem;
      height: 10rem;
      /* background-color: #f5f5f5; */
    }
    /* 滚动条上的按钮 (上下箭头). */
    &::-webkit-scrollbar-button {
      display: none;
    }
    /* 滚动条上的滚动滑块. */
    &::-webkit-scrollbar-thumb {
      background-color: rgb(175, 175, 175, 0.8);
      border-radius: 50px;
    }
  }
  .right_charts {
    height: calc(100vh - 200rem) !important;
    overflow-y: scroll;
    flex: 1;
    .charts {
      background: white;
      padding-bottom: 18rem;
      margin: 25rem auto;
      min-height: 340rem;
      width: 95%;
      box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
      border-radius: 20rem;
      border: 1rem solid #ececec;
    }
  }
}
::v-deep .el-tabs__header {
  margin: 0;
}
.isActive {
  color: white !important;
  background: #6cb9f5 !important;
  border-bottom: 0 !important;
}
.kaodian {
  height: 100vh;
  overflow-y: scroll;
}

::v-deep .el-tabs__nav {
  overflow: scroll !important;
}
</style>